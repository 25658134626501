import React from 'react';
import HomePageTemplate from '@tra-sg/gatsby-theme-c360-portal/src/components/HomePageTemplate';

const HomePage = ({ data }) => {
//   const { frontmatter } = data.mdx;

  return (
    <HomePageTemplate
      title={"c360 Platform"}
      metaTitle={"Home | c360"}
      metaDescription={"Home page for the c360 platform"}
    //   offerings={frontmatter.offerings}
    //   contacts={frontmatter.contacts}
    />
  );
};

export default HomePage;
